<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader header-text="Edit Form">
      <template #subheader>
        <div>Update the following information and select “Save”</div>
      </template>
    </PageHeader>

    <v-row>
      <v-col cols="12" lg="6">
        <CustomTextInput
          v-model="form.name"
          required
          header="Name"
          placeholder="Enter a name for this Form"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <CustomTextInput
          v-model="form.url"
          header="URL"
          placeholder="Enter the url where this Form exists"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <CustomDropdown
          v-model="form.type"
          header="Form Type"
          placeholder="Select a type"
          :items="formTypes"
          required
          @change="handleTypeChange"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <TagsInput
          v-model="formTags"
          :items="tagNames"
          header="Tags"
          placeholder="Select / Add Tag(s) for this Form"
        />
      </v-col>
    </v-row>

    <v-divider v-if="canConfigureSubmissionRules" class="my-4" />

    <template
      v-for="(component, key) in editFormConfigurationSections"
    >
      <component
        :is="component"
        :key="'form-configuration-section-' + key"
        :form="form"
        @update="update"
      />
    </template>

    <div v-if="canConfigureSubmissionRules">
      <h4 class="mb-4">
        Form Submission Rules
      </h4>

      <v-row class="mt-3">
        <v-col cols="12" lg="6">
          <CustomDropdown
            v-model="form.submissionRules.contactDataMethod"
            header="How to handle contact data"
            placeholder="Select a method"
            :required="canConfigureSubmissionRules"
            :items="contactDataMethods"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <div class="pt-lg-8">
            {{ contactDataMethodDescription }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12" lg="6">
          <div class="field__subheader d-flex">
            <span class="field__subheader--required"> * </span> How to look up existing contacts
          </div>
          <ol class="my-5">
            <li
              v-for="(identifier, key) in contactLookupOrder"
              :key="'id-' + identifier"
            >
              {{ getLookupText(identifier, key) }}

              <CustomCancelButton
                class="ml-3"
                @click="deleteLookupItem(key)"
              />
            </li>
          </ol>

          <div>
            <v-menu
              v-if="identifiers.length"
              :offset-y="true"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="elevation-0 outlined-btn"
                  height="34px"
                  plain
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('click')"
                >
                  <v-icon color="#2b84eb" size="16" class="mr-0">
                    mdi-plus
                  </v-icon>
                  Add an Identifier
                </v-btn>
              </template>
              <OuterList
                title="Available Identifiers"
                :items="identifiers"
                @click="addLookup"
              />
            </v-menu>
          </div>
        </v-col>
        <v-col cols="12" lg="6">
          <div>
            Since our system allows for multiple unique identifiers per contact, you are required to specify which
            identifiers your form contains. You are also required to specify the order in which we'll try identifiers
            to find matches for contacts. There may be rare cases where one contact in our system has the email address
            that was submitted in the form, and a different contact has another identifier submitted in the same form.
            In this case, we will return an error message via the API response to alert you of this unexpected result.
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12" lg="6">
          <CustomDropdown
            v-model="form.submissionSource"
            header="Form Submission Source"
            placeholder="Select a source"
            autocomplete
            :hint="submissionSourceHint"
            :required="canConfigureSubmissionRules"
            item-value="id"
            item-text="name"
            :items="sources"
          />
        </v-col>
      </v-row>
      <FormSubmissionActions
        :submission-actions="submissionActions"
        class="mt-5"
        @update="updateSubmissionActions"
      />

      <div v-if="form.uuid && form.type === 'internal'" class="my-3">
        <div class="label mt-10">
          Form Submission Example
        </div>
        <pre class="json-view">
POST {{ apiUrl }}/forms/{{ form.uuid }}/submit

Headers:
X-Account: {{ $store.getters["user/account"].accountId }}
Authorization: API_TOKEN xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
Content-Type: application/json

Request Body:
{{ exampleData }}
        </pre>
      </div>
      <div v-else-if="form.type === 'internal'">
        <v-row class="mt-5">
          <v-col cols="12" lg="6">
            <v-alert
              type="info"
              class="mt-2"
            >
              A Form Submission API Example will be shown here after this form has been saved.
            </v-alert>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="flex-fill d-flex justify-end mr-6 my-6">
      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="150px"
        @click="onSave()"
      >
        Save
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import TagsInput from "@/sharedComponents/TagsInput";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import OuterList from "@/sharedComponents/OuterList";
import CustomCancelButton from "@/sharedComponents/CustomCancelButton";
import FormSubmissionActions from "./components/FormSubmissionActions";
import { hasError } from "@/mixins/has_error";
import dayjs from "dayjs";

export default {
  name: "EditFormView",
  components: {
    CustomTextInput,
    CustomDropdown,
    TagsInput,
    PageHeader,
    OuterList,
    CustomCancelButton,
    FormSubmissionActions,
  },
  mixins: [hasError],
  data() {
    return {
      form: {},
      tags: [],
      tagNames: [],
      sources: [],
      formTags: [],
      contactDataMethods: [
        {
          label: 'Upsert contact data',
          value: 'upsert',
          description: 'Upsert: If a match is found, ALL contact data will be updated using the values sent via API requests. Any new contact data will be created as new.'
        },
        {
          label: 'Insert only new data',
          value: 'insert',
          description: 'Insert: If a match is found, the Pulse API will not update the matching contact data. Any new contact data (ie: emails, location, phone data) for matching contacts and any new contacts in the API request will be created.'
        },
        {
          label: 'Reject matching contacts',
          value: 'reject',
          description: 'Reject: If a match is found, the Pulse API will return an error in the response. No contact data will be updated in this case. Contact data will only be created if there are no matches.'
        },
      ],
      apiUrl: process.env.VUE_APP_API_URL,
      exampleData: {
        "salutation": "Dr",
        "firstName": "Example",
        "middleName": "",
        "lastName": "Example",
        "credentials": "MD",
        "gender": "M",
        "npi": "1234567890",
        "specialty": "Oncology",
        "contactType": "HCP",
        "isTest": false,
        "emailAddress": "example@email.com",
        "emailIsSubscribed": true,
        "emailIsDefault": true,
        "company": "The Night's Watch",
        "addressLine1": "1 Wall St.",
        "addressLine2": "Suite 100",
        "city": "New York",
        "state": "NY",
        "postalCode": "10001",
        "country": "USA",
        "locationIsDefault": true,
        "phone": "5165551212",
        "phoneIsCallSubscribed": true,
        "phoneIsCallDefault": true,
        "phoneIsSmsSubscribed": true,
        "phoneIsSmsDefault": true,
        "customFields": {},
      }
    };
  },
  computed: {
    editFormConfigurationSections() {
      return this.$store.getters['user/pluginComponents']('editFormConfiguration');
    },
    canConfigureSubmissionRules() {
      return this.formTypes.find(type => type.value === this.form?.type)?.canConfigureSubmissionRules ?? false;
    },
    formTypes() {
      let formTypes = [
        { label: "Internal (API Access)", value: "internal", canConfigureSubmissionRules: true },
        { label: "Third-Party Form", value: "third-party", canConfigureSubmissionRules: false },
      ];

      formTypes.push(...this.$store.getters['user/formVendors']);

      return formTypes;
    },
    contactDataMethodDescription() {
      if (!this.form.submissionRules?.contactDataMethod) {
        return '';
      }

      return this.contactDataMethods.find(
        i => i.value === this.form.submissionRules.contactDataMethod
      )?.description ?? '';
    },
    submissionSourceHint() {
      if (!Array.isArray(this.form.submissionRules?.submissionActions)
        && Object.keys(this.form.submissionRules?.submissionActions).includes('create-action')
      ) {
        return 'The Source to use when new contact data and actions are being created by the form submissions';
      }

      return 'The Source to use when new contact data is being created by the form submissions';
    },
    contactLookupOrder() {
      return this.form.submissionRules?.contactLookupOrder ?? [];
    },
    submissionActions() {
      return Array.isArray(this.form.submissionRules?.submissionActions ?? [])
        ? {}
        : this.form.submissionRules.submissionActions;
    },
    allIdentifiers() {
      const identifiers = [
        {
          label: "Email Address",
          value: "email.emailAddress",
        },
      ];

      const account = this.$store.getters['user/account'];

      if (account.accountType === 'HCP') {
        identifiers.push({
          label: 'NPI',
          value: 'contact.npi',
        })
      }

      if (account.customFieldDefinition.contact.length > 0) {
        identifiers.push(...account.customFieldDefinition.contact.filter(i => i.isUnique).map(j => ({
          label: j.name,
          value: 'contact.customFields.' + j.fieldName,
        })))
      }

      return identifiers;
    },
    identifiers() {
      return this.allIdentifiers.filter(i => !this.form.submissionRules.contactLookupOrder.includes(i.value));
    }
  },
  async created() {
    await this.getTags();
    await this.getSources();

    if (this.$route.params.id) {
      await this.getForm();
      await this.getTagsOfForm();

      const customFields = this.$store.getters['user/account']?.customFieldDefinition;
      if (customFields?.contact) {
        const exampleCustomFields = {};
        customFields.contact.forEach(field => {
          let exampleValue = '';
          switch (field.type) {
            case 'boolean':
              exampleValue = true;
              break;
            case 'date':
              exampleValue = dayjs(new Date()).format('YYYY-MM-DD');
              break;
            case 'number':
              exampleValue = 12345;
              break;
            default:
              exampleValue = 'Example';
          }
          exampleCustomFields[field.fieldName] = exampleValue;
        });

        this.exampleData.customFields = exampleCustomFields;
      }
    }
  },
  methods: {
    update(form) {
      this.form = form;
    },
    async getForm() {
      try {
        const result = await this.$rest.forms.get_resource(this.$route.params.id);
        this.form = { ...result.data };

        if (this.form.submissionSource) {
          this.form.submissionSource = this.form.submissionSource.id;
        }
        if (this.canConfigureSubmissionRules && Array.isArray(this.form.submissionRules)) {
          this.form.submissionRules = {
            contactDataMethod: 'upsert',
            contactLookupOrder: [],
            submissionActions: {},
          };
        }
      } catch (e) {
        this.$store.commit('snackbar/showMessage', {
          color: 'error',
          content: 'Form not found',
        });
        await this.$router.push({name: 'Forms'});
      }
    },
    addLookup(value) {
      if (Array.isArray(this.form.submissionRules)) {
        this.$set(
          this.form,
          'submissionRules',
          {
            contactDataMethod: 'upsert',
            contactLookupOrder: [],
            submissionActions: {},
          }
        );
      }

      const contactLookupOrder = [...this.form.submissionRules.contactLookupOrder];

      contactLookupOrder.push(value.value);

      this.$set(
        this.form.submissionRules,
        'contactLookupOrder',
        contactLookupOrder
      );
    },
    getLookupText(value, key) {
      const id = this.allIdentifiers.find(i => i.value === value);

      if (!id) {
        this.form.submissionRules.contactLookupOrder.splice(key, 1);
      }

      return id?.label;
    },
    deleteLookupItem(key) {
      this.form.submissionRules.contactLookupOrder.splice(key, 1);
    },
    handleTypeChange() {
      let rules = [];

      if (this.canConfigureSubmissionRules) {
        rules = {
          contactDataMethod: 'upsert',
          contactLookupOrder: [],
          submissionActions: {},
        };
      }

      this.$set(this.form, 'submissionRules', rules);
    },
    updateSubmissionActions(submissionActions) {
      this.$set(
        this.form.submissionRules,
        'submissionActions',
        submissionActions
      );
    },
    async onSave() {
      const updateData = {
        ...this.form,
        tagItems: this.formTags.map(tagName => {
          const tagInstance = this.tags.find(
            tagItem => tagItem.name.toLowerCase() === tagName.toLowerCase()
          );

          return tagInstance ? {id: tagInstance.id} : {name: tagName};
        }),
      };

      if (!this.canConfigureSubmissionRules) {
        updateData.submissionRules = [];
      }

      try {
        let response = null;
        if (this.form.id) {
          response = await this.$rest.forms.put_resource(this.form.id, updateData);
        } else {
          response = await this.$rest.forms.post_resource(updateData);
        }
        if (!response.data) {
          return;
        }
      } catch (e) {
        const errorResponse = e.response?.data ?? null;
        if (errorResponse) {
          let errors = '';
          errorResponse?.errors?.forEach(item => {
            errors += (item.error + "\r \n");
          });

          return this.$store.commit('snackbar/showMessage', {
            'content': errors ? errors : 'An unexpected error occurred.',
            'color': 'error',
          });
        }
      }

      this.$store.commit('snackbar/showMessage', {
        content: 'Saved successfully!',
        color: 'info',
      });

      await this.$router.push({name: 'Forms'});
    },
    async getTags() {
      const response = await this.$rest.tags
        .getCollection({
          fields: ['id', 'name'],
          ignorePagination: true,
        })
        .catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.tags = response.data.items;
      this.tagNames = this.tags.map(tagItem => tagItem.name);
    },
    async getTagsOfForm() {
      const response = await this.$rest.forms
        .getTags(this.form.id)
        .catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.formTags = response.data.items.map(emailTag => emailTag.tag.name);
    },
    async getSources() {
      const response = await this.$rest.source
        .get_collection({
          fields: ['id', 'name'],
          ignorePagination: true,
          sort: ["name:asc"]
        })
        .catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.sources = response.data.items;
    },
  },
};
</script>

<style lang="scss" scoped>
.json-view {
  color: #000;
  padding: 12px;
  background-color: rgb(197, 197, 188);
}
</style>